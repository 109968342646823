'use strict';

angular.module('informaApp')
    .directive('infPredictiveAnalytics', ["DrugIndicationService", "BiomedtrackerUrlBuilder", "OmnitureSvc", "DiseaseNameHelper", "DrugHideSvc", "$rootScope", '$interval',
        function (DrugIndicationService, BiomedtrackerUrlBuilder, OmnitureSvc, DiseaseNameHelper, DrugHideSvc, $rootScope, $interval) {
            return {
                restrict: 'E',
                templateUrl: 'directives/inf-predictive-analytics/template.ptl.html',
                scope: {
                    tooltips: '=',
                    source: "=",
                    onLoad: "=",
                    showQlsDetail: '=',
                },
                filterSource: function (data) {
                    var tableDisplayData = [];
                    for (var i = 0; i < data.length; i++) {
                        for (var j = 0; j < data[i].drugIndicationIds.shown.length; j++) {
                            if (data[i].eventPhaseName[j] != "Suspended" && data[i].POTSEstimate[j] > 0) {
                                tableDisplayData.push({   
                                    drugIndicationIds: data[i].drugIndicationIds.shown[j],
                                    indicationId: data[i].indicationId[j],
                                    indication: data[i].indication[j],
                                    drugId: data[i].drugId[j],
                                    drugBrandName: data[i].drugBrandName[j],
                                    diseaseShortName: data[i].diseaseShortName[j],
                                    diseaseGroup: data[i].diseaseGroup[j],
                                    eventPhaseName: data[i].eventPhaseName[j],
                                    companyId: data[i].companyId[j],
                                    companyName: data[i].companyName[j],
                                    leadCompany: data[i].leadCompany[j],
                                    POAEstimate: data[i].POAEstimate[j],
                                    POABaseline: data[i].POABaseline[j],
                                    POAOtherFeaturesInfluence: data[i].POAOtherFeaturesInfluence[j],
                                    POAOtherFeaturesRawInfluence: data[i].POAOtherFeaturesRawInfluence[j],
                                    POAFeature: data[i].POAFeature[j],                            
                                    POTSEstimate: data[i].POTSEstimate[j],
                                    POTSBaseline: data[i].POTSBaseline[j],
                                    POTSOtherFeaturesInfluence: data[i].POTSOtherFeaturesInfluence[j],
                                    POTSOtherFeaturesRawInfluence: data[i].POTSOtherFeaturesRawInfluence[j],
                                    POTSFeature: data[i].POTSFeature[j] 
                                });
                            }
                        }
                    }
                    
                    return tableDisplayData.sort((x, y) => {
                                if (x.indication < y.indication) {return -1;}
                                if (x.indication > y.indication) {return 1;}
                                return 0;
                            }).sort((x, y) => {
                                if (x.companyName < y.companyName) {return -1;}
                                if (x.companyName > y.companyName) {return 1;}
                                return 0;
                            }).sort((x, y) => {
                                if (x.drugBrandName < y.drugBrandName) {return -1;}
                                if (x.drugBrandName > y.drugBrandName) {return 1;}
                                return 0;
                            });
                },
                getLinks: function (source) {
                    if (!source) {
                        return
                    }
    
                    source.map(function (indication) {
                        indication.drugLink = BiomedtrackerUrlBuilder.buildDrugUrl(indication.drugId)

                        indication.indicationLink = BiomedtrackerUrlBuilder.buildIndicationUrl(indication.indicationId);

                        indication.companyLink = BiomedtrackerUrlBuilder.buildCompanyUrl(indication.companyId);
    
                        return indication;
                    });
                },
                link: function (scope, element, attr) {
                    var sender = this;
                    var _this = this;

                    scope.popupOptions = {};
                    
                    scope.onTableLoad = function () {
                        scope.onLoad();
                    }

                    scope.onExitToBiomedtracker = () => {
                        OmnitureSvc.exitToBiomedtracker.trackFromMainTable();
                    };

                    function updateSource() {
                        scope.originalSource = _this.filterSource(scope.source);
                        _this.getLinks(scope.originalSource);
                    }

                    updateSource();

                    scope.$watch('source', function () {
                        updateSource();
                    }, true);
                }
            }
        }]);
